<template>
  <div> 
    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        flat
        nav
      >
        <template>
          <div @click="menu = true">
            <app-bar-item>
              <v-list-item-title v-text="'Налаштування'" />
            </app-bar-item>
          </div>
          <div @click="onLogOut" >
            <app-bar-item>
              <v-list-item-title v-text="'Вихiд'" />
            </app-bar-item>
          </div>
        </template>
      </v-list>
    </v-menu>
    
    <div id="settings-wrapper">
      <v-card
        id="settings"
        dark
        flat
        link
        min-width="100"
        style="position: fixed; top: 60px; right: -90px; border-radius: 8px; z-index: 1;"
      >
      </v-card>

      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        activator="#settings"
        bottom
        content-class="v-settings"
        left
        nudge-left="8"
        offset-x
        origin="top right"
        transition="scale-transition"
      >
        <v-card
          class="text-center mb-0"
          width="300"
        >
          <v-card-text>
            <strong class="mb-3 d-inline-block">SIDEBAR FILTERS</strong>

            <v-item-group
              v-model="color"
              mandatory
            >
              <v-item
                v-for="color in colors"
                :key="color"
                :value="color"
              >
                <template v-slot="{ active, toggle }">
                  <v-avatar
                    :class="active && 'v-settings__item--active'"
                    :color="color"
                    class="v-settings__item mx-1"
                    size="25"
                    @click="toggle"
                  />
                </template>
              </v-item>
            </v-item-group>

            <v-divider class="my-4 secondary" />

            <strong class="mb-3 d-inline-block">SIDEBAR BACKGROUND</strong>

            <v-item-group
              v-model="gradient"
              mandatory
            >
              <v-item
                v-for="(scrim, index) in gradients"
                :key="scrim"
                :value="index"
                class="mx-1"
              >
                <template v-slot="{ active, toggle }">
                  <v-avatar
                    :class="active && 'v-settings__item--active'"
                    :color="scrim"
                    class="v-settings__item"
                    size="24"
                    @click="toggle"
                  />
                </template>
              </v-item>
            </v-item-group>

            <v-divider class="my-4 secondary" />

            <v-row
              align="center"
              no-gutters
            >
              <v-col cols="auto">
                Dark Mode
              </v-col>

              <v-spacer />

              <v-col cols="auto">
                <v-switch
                  v-model="$vuetify.theme.dark"
                  class="ma-0 pa-0"
                  color="secondary"
                  hide-details
                />
              </v-col>
            </v-row>

            <v-divider class="my-4 secondary" />

            <v-row
              align="center"
              no-gutters
            >
              <v-col cols="auto">
                Sidebar Mini
              </v-col>

              <v-spacer />

              <v-col cols="auto">
                <v-switch
                  v-model="mini"
                  class="ma-0 pa-0"
                  color="secondary"
                  hide-details
                />
              </v-col>
            </v-row>

            <v-divider class="my-4 secondary" />

            <v-row
              align="center"
              no-gutters
            >
              <v-col cols="auto">
                Sidebar Image
              </v-col>

              <v-spacer />

              <v-col cols="auto">
                <v-switch
                  v-model="drawerImage"
                  class="ma-0 pa-0"
                  color="secondary"
                  hide-details
                />
              </v-col>
            </v-row>

            <v-divider class="my-4 secondary" />

            <!-- <strong class="mb-3 d-inline-block">IMAGES</strong>

            <v-card
              :disabled="!drawerImage"
              flat
            >
              <v-item-group
                v-model="image"
                class="d-flex justify-space-between mb-3"
              >
                <v-item
                  v-for="(img, index) in images"
                  :key="img"
                  :value="index"
                  class="mx-1"
                >
                  <template v-slot="{ active, toggle }">
                    <v-sheet
                      :class="active && 'v-settings__item--active'"
                      class="d-inline-block v-settings__item"
                      @click="toggle"
                    >
                      <v-img
                        :src="img"
                        height="100"
                        width="50"
                      />
                    </v-sheet>
                  </template>
                </v-item>
              </v-item-group>
            </v-card> -->
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
import Proxyable from 'vuetify/lib/mixins/proxyable'
import { get, sync } from 'vuex-pathify'
import { mapActions } from 'vuex'

  export default {
    name: 'DefaultAccount',
    mixins: [Proxyable],
    data: () => ({
      menu: false,
      profile: [
        { title: 'Profile' },
        { title: 'Settings' },
        { divider: true },
        { title: 'Log out' },
      ],
      color: '#E91E63',
      colors: [
        '#9C27b0',
        '#00CAE3',
        '#4CAF50',
        '#ff9800',
        '#E91E63',
        '#FF5252',
      ],
      menu: false,
      saveImage: '',
    }),
    computed: {
      ...sync('app', [
        'drawer',
        'drawerImage',
        'mini',
      ]),
      ...sync('user', [
        'drawer@gradient',
        'drawer@image',
      ]),
      ...get('user', [
        'images',
        'gradients',
      ]),
    },

    watch: {
      color (val) {
        this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].primary = val
      },
    },
    methods: {
      ...mapActions(['logOut']),
      onLogOut() {
        this.logOut()
          .then(() => {
            this.$router.push('/login/')
          })
      }
    }
  }
</script>
